import { useState } from "react";
import SelectAsset from "../../components/Assets/SelectAsset";
import SwitchInput from "../../components/CustomUI/CustomInputs/SwitchInput";
import { Stack, Checkbox, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUI } from "../../context/CustomIUProvider";
import AssetThumb from "../../components/Assets/AssetThumb";
import ClosestVehicle from "../../components/Shippings/ClosestVehicle";

function AddShippingSt2({ setData, formik }) {
  const { t } = useTranslation();
  const { closeDialog, showDialog } = useUI();

  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [selectedTrailerId, setSelectedTrailerId] = useState(null);

  const [wasDefault, setWasDefaultd] = useState(null);

  const DialogDefaulrAsssets = ({ data, type }) => {


  const [driverChecked, setDriverChecked] = useState(true);
  const [vehicleChecked, setVehicleChecked] = useState(true);
  const [trailerChecked, setTrailerChecked] = useState(true);



    const setDefaultItems = () => {
      if (vehicleChecked) setSelectedVehicleId(data.vehicle_id);
      else setSelectedVehicleId(null);
      if (driverChecked) setSelectedDriverId(data.driver_id);
      if (trailerChecked) setSelectedTrailerId(data.trailer_id);

      setWasDefaultd(type);
      closeDialog();
    };

    return (
      <Box>
        <Typography variant="body">
          Цей вибір має повязані з ним по замовчанню елементи. Вибрати їх?
        </Typography>


        <Stack direction="row" sx={{ justifyContent: "space-around", mt: 3 }}>
          {(type === "driver" || type === "trailer") && data.vehicle_id ? (
            <Stack direction="row" spacing={1}>
              <Box>
                <Checkbox
                  checked={vehicleChecked}
                  onChange={() => setVehicleChecked(!vehicleChecked)}
                  sx={{ mt: 1 }}
                />
              </Box>

              <AssetThumb element={data} type={"vehicles"} />
            </Stack>
          ) : null}

          {(type === "driver" || type === "vehicle") && data.driver_id ? (
            <Stack direction="row" spacing={1}>
              <Box>
                <Checkbox
                  checked={driverChecked}
                  onChange={() => setDriverChecked(!driverChecked)}
                  sx={{ mt: 1 }}
                />
              </Box>
              <AssetThumb element={data} type={"trailers"} />
            </Stack>
          ) : null}
  

        {(type === "vehicle" || type === "trailer") && data.trailer_id ? (
            <Stack direction="row" spacing={1}>
              <Box>
                <Checkbox
                  checked={trailerChecked}
                  onChange={() => setTrailerChecked(!trailerChecked)}
                  sx={{ mt: 1 }}
                />
              </Box>
              <AssetThumb element={data} type={"trailers"} />
            </Stack>
          ) : null}
        </Stack>

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            fullWidth
            onClick={() => {
              closeDialog();
            }}
            variant="outlined"
          >
            {t("general.cancel")}
          </Button>
          <Button fullWidth onClick={setDefaultItems} variant="contained">
            {t("general.ok")}
          </Button>
        </Stack>
      </Box>
    );
  };

  const changeDriver = (data) => {
    formik.setFieldValue("driverId", data ? data.driver_id : null);
    setData(data, "driver");
    if (wasDefault === null || wasDefault === "driver") {
      if (data?.vehicle_id || data?.trailer_id) {
        showDialog({
          content: <DialogDefaulrAsssets data={data} type="driver" />,
        });
      }
    }
  };



  const changeVehicle = (data) => {
    formik.setFieldValue("vehicleId", data ? data.vehicle_id : null);
    setData(data, "vehicle");
    if (wasDefault === null || wasDefault === "vehicle") {
      if (data?.vehicle_id || data?.trailer_id) {
        showDialog({
          content: <DialogDefaulrAsssets data={data} type="vehicle" />,
        });
      }
    }
  };


  const changeTrailer = (data) => {
    formik.setFieldValue("trailerId", data ? data.trailer_id : null);
    setData(data, "trailer");

    if (wasDefault === null || wasDefault === "trailer") {
      if (data.vehicle_id || data.trailer_id) {
        showDialog({
          content: <DialogDefaulrAsssets data={data} type="trailer" />,
        });
      }
    }


  };

  const handleCloseVehicle = () => {
    showDialog({
      
      content: <ClosestVehicle/>
    })
  }

  return (
    <>
    
      <Typography variant="h3">{t("general.assets")}</Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Typography variant="h6">Власний</Typography>
        <SwitchInput />
        <Typography variant="h6">Контрагент</Typography>


        <Button variant="outlined" size="small" onClick={handleCloseVehicle}>Знайти найближчий автомобіль</Button>
      </Stack>

      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <SelectAsset
          type="drivers"
          onChange={changeDriver}
          selectedId={selectedDriverId}
        />
        <SelectAsset
          type="vehicles"
          onChange={changeVehicle}
          selectedId={selectedVehicleId}
        />
        <SelectAsset
          type="trailers"
          onChange={changeTrailer}
          selectedId={selectedTrailerId}
        />
      </Stack>
    </>
  );
}

export default AddShippingSt2;
