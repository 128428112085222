import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import AssetThumb from "./AssetThumb";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { t } from "i18next";

function SelectAsset({ type, onChange, selectedId }) {
  const [assets, setAssets] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const auth = useAuthUser();
  const company_id = auth.company_id;
  const authToken = useAuthHeader();

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${config.serverUrl}/axios/cards/fetchAssets.php`,
        { company_id: company_id, assets: type },
        headers
      )
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [company_id, type, authToken]);

  useEffect(() => {
    if (selectedId && assets.length > 0) {
      const selected = assets.find((asset) => {
        switch (type) {
          case "vehicles":
            return asset.vehicle_id === selectedId;
          case "drivers":
            return asset.driver_id === selectedId;
          case "trailers":
            return asset.trailer_id === selectedId;
          default:
            return false;
        }
      });
      setSelectedElement(selected || null);
    }
  }, [selectedId, assets, type]);

  // Функція для отримання назви залежно від типу
  const getOptionLabel = (option) => {
    switch (type) {
      case "vehicles":
        return option.vehicleNumber;
      case "drivers":
        return `${option.firstname} ${option.lastname}`;
      case "trailers":
        return option.trailerNumber;
      default:
        return "Unknown";
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedElement(newValue);
    onChange(newValue);
  };

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Autocomplete
        options={assets}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        value={selectedElement}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("shippings.assets.select" + type.slice(0, -1).charAt(0).toUpperCase() + type.slice(1, -1))}
            variant="outlined"
          />
        )}
      />
      {selectedElement ? (
        <AssetThumb element={selectedElement} type={type} />
      ) : null}
    </Stack>
  );
}

export default SelectAsset;
