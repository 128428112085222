import { Box, Typography, Stack } from "@mui/material";
import config from "../../config";
import noImage from "../../assets/img/no_image.jpeg";

function AssetThumb({ element, type }) {
  const nameType = type.slice(0, -1);

  const hasAvatar = element[nameType + "Avatar"] === "1";
  const imageUrl = hasAvatar
    ? `${config.serverUrl}/images/${type}/${element[nameType + "_id"]}.jpeg`
    : noImage;

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <img
        src={imageUrl}
        alt="avatar"
        style={{ borderRadius: "8px", height: "64px" }}
      />
      <Box>
        {nameType === "driver" ? (
          <Typography variant="h4">
            {`${element?.firstname?.charAt(0)}. ${element?.lastname}`}
          </Typography>
        ) : (
          <>
            <Typography variant="h4">{element[nameType + "Number"]}</Typography>
            <Typography variant="h5">{element[nameType + "Name"]}</Typography>
          </>
        )}
      </Box>
    </Stack>
  );
}

export default AssetThumb;
